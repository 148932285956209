/* NavBar.css */
.nav-bar {
    display: flex;
    gap: 20px;
    background-color: #2d3436;  /* Charcoal gray background */
    padding: 15px 30px;
    justify-content: space-around;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adding shadow for depth */
  }
  
  .nav-bar a {
    text-decoration: none;
    color: #ffffff; /* White text for contrast */
    font-weight: 500;
    padding: 12px 20px;
    width: 160px;
    text-align: center;
    transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s;
  }
  
  .nav-bar a:hover {
    background-color: #00cec9; /* Teal hover color */
    color: #ffffff; /* Keep white text on hover */
    transform: scale(1.05); /* Slight enlargement on hover */
  }
  
  .nav-bar a.active {
    font-weight: bold;
    background: linear-gradient(45deg, #fd79a8, #00cec9); /* Coral/Teal gradient for active link */
    border-radius: 8px;
    color: #ffffff;
  }
  
  button {
    background-color: #fd79a8; /* Vibrant coral for buttons */
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    transition: background-color 0.3s ease, transform 0.3s;
  }
  
  button:hover {
    background-color: #00cec9; /* Switch to teal on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  h1, h2 {
    font-family: 'Poppins', sans-serif; /* Modern clean font */
    color: #ffffff;
    letter-spacing: 1px;
  }
  